@use 'src/styles/variables' as variables;

.container {
	position: relative;
}

.languageSwitch {
	position: absolute;
	right: 0;
	z-index: var(--z-index-5);
	padding: var(--space-inset-xl) var(--space-inset-2xl);
	&.withInfoBanner {
		top: var(--global-menu-height);
	}

	&:not(.hasSubMenuV1) {
		padding-bottom: var(--space-stack-md);
	}

	&.theme--dark {
		a {
			color: var(--text-light);
		}
	}

	&.theme--light {
		a {
			color: var(--text-dark);
		}
	}

	&.hasSubMenuV1 {
		padding-right: var(--space-inset-3xl);

		@media (max-width: variables.$breakpoint-max) {
			padding-right: var(--space-stack-lg);
		}

		a {
			@media (min-width: variables.$breakpoint-xl) {
				color: var(--text-dark);
			}
		}
	}
}

@media (max-width: variables.$breakpoint-xl) {
	.languageSwitch {
		top: 4rem;
	}
}

@media (max-width: variables.$breakpoint-md) {
	.languageSwitch {
		top: var(--space-stack-2xl);
		padding: var(--space-inset-md);
	}
}
