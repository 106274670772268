@use 'src/styles/variables' as variables;

.container {
	--body-color: var(--text-light);
	--text-color: var(--text-heading-color);
	position: fixed;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: var(--z-index-10);
	transition: top 0.2s ease-in-out;
	transition: all 0.5s;

	&.withInfoBanner {
		top: var(--global-info-banner-height);
	}

	&.menuOpen,
	&.searchOpen {
		z-index: var(--z-index-max);
	}
}

.header {
	display: flex;
	justify-content: space-between;
	padding: var(--space-inset-md) var(--space-inset-md);
	height: var(--global-menu-height);
}

.loginBtnMobile {
	display: none;
}

.searchOpen .loginBtnMobile {
	margin-left: auto;
}

.backdrop {
	position: absolute;
	top: 0;
	left: 0;
	z-index: var(--z-index-3);
	background-color: var(--black-60-alpha);
	width: 100vw;
	height: 100vh;
	overflow: hidden;
}

.menuButton {
	flex-shrink: 0;
	margin-right: var(--space-inset-lg);
	border: 0;
	background: transparent;
	padding: 0;

	.icon {
		width: 20px;
		min-width: 20px;
	}
}

.logoWrapper {
	position: relative;
	margin-right: var(--space-inset-3xl);
	text-align: center;
}

.logo {
	display: flex;
	position: relative;
	width: 8.625rem;
	@media (max-width: 30rem) {
		width: 6.25rem;
	}
}

.navLeft {
	display: flex;
	align-items: center;
}

.navAnchor,
.navAnchorContact {
	position: relative;
	margin-right: var(--space-inset-xl);
	font-weight: 600;
	line-height: 1.2;
	text-decoration: none;
	white-space: nowrap;

	a:link,
	a:visited,
	a:hover,
	a:active,
	a:focus {
		color: var(--text-light);
	}

	&.active {
		a:link,
		a:visited,
		a:hover,
		a:active,
		a:focus {
			transition: all 0.15s ease-in-out;
			border-bottom-color: var(--link-hover-effect-color);
		}
	}
}

.navAnchorContact {
	margin-left: var(--space-inset-xl);
	padding-right: 0;

	@media (max-width: variables.$breakpoint-sm) {
		margin-right: 0;
	}
}

.navRight {
	display: flex;
	flex: 1;
	justify-content: flex-end;
	align-items: center;

	a {
		font-weight: 500;
	}
}

.searchField {
	margin-right: var(--space-inset-lg);
}

@media (max-width: variables.$breakpoint-md) {
	.menuButton {
		margin-right: var(--space-inline-md);
	}

	.supportBtn.supportBtn.supportBtn {
		padding-right: var(--space-inline-md);
		padding-left: 0;
	}
}

@media (min-width: variables.$breakpoint-sm) {
	.header {
		padding: var(--space-inset-lg) var(--space-inset-lg);
	}
}

@media (max-width: 430px) {
	.navAnchorContact,
	.loginBtnMobile.loginBtnMobile {
		display: none;
	}
}
@media (max-width: 576px) {
	.searchOpen {
		.logoWrapper,
		.loginBtn,
		.loginBtnMobile {
			display: none;
		}
	}
}
@media (max-width: 700px) {
	.linkWrapper {
		display: none;
	}
}
@media (max-width: 783px) {
	.searchOpen .loginBtnMobile {
		display: none;
	}
}
@media (max-width: 900px) {
	.searchOpen .navRight {
		justify-content: flex-start;
	}
	.searchOpen .navAnchorContact {
		display: none;
	}
	.loginBtn {
		display: none;
		padding: 0.5rem;
	}
	.loginBtnMobile {
		display: block;
		a {
			color: var(--text-light);
		}
	}
	.linkWrapper > :nth-child(n + 2) {
		display: none;
	}
}
@media (max-width: 1000px) {
	.searchOpen .logoWrapper {
		margin-right: var(--space-inset-xl);
	}
	.searchOpen .linkWrapper {
		display: none;
	}
	.linkWrapper > :nth-child(n + 3) {
		display: none;
	}
}
@media (max-width: 1200px) {
	.searchOpen .linkWrapper > :nth-child(n + 2) {
		display: none;
	}
}
@media (max-width: 1392px) {
	.searchOpen .linkWrapper > :nth-child(n + 3) {
		display: none;
	}
}
