.container {
	position: fixed;
	top: 0;
	z-index: var(--z-index-10);
	width: 100%;
	height: var(--global-info-banner-height);
}

.inner {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;

	p {
		margin: 0 !important;
	}
}

.icon {
	margin-right: var(--space-inset-md);
}
