@use 'src/styles/functions' as functions;
@use 'src/styles/variables' as variables;

// AddSearch UI CSS styling
// CSS copied from TextField, Button and LinkButton

.hidden {
	display: none;
}

.container {
	position: relative;
	flex: 1;
	max-width: 400px;

	:global .addsearch-searchfield {
		display: flex;
	}

	:global .search-field-wrapper {
		display: flex;
		flex: 1;
		flex-direction: column;
		align-items: flex-start;
	}

	input {
		align-content: center;
		align-items: center;
		align-self: center;
		border: functions.rem(1px) solid var(--text-light);
		border: functions.rem(1px) solid var(--border-color-light);

		// TextField .bordered
		border-radius: functions.rem(8px);
		width: 100%;

		&::placeholder {
			color: var(--custom-text-placeholder);
		}

		&::-webkit-calendar-picker-indicator {
			display: none !important;
		}

		&[type='search'] {
			padding: 0.3rem 1rem;
		}

		&[type='search']::-webkit-search-decoration,
		&[type='search']::-webkit-search-cancel-button,
		&[type='search']::-webkit-search-results-button,
		&[type='search']::-webkit-search-results-decoration {
			-webkit-appearance: none;
		}
	}

	button {
		display: none;
		position: absolute;
		top: 0.3rem;
		right: 0.3rem;
		bottom: 0.3rem;
		z-index: var(--z-index-3);
		cursor: pointer;

		border: 0;
		border-radius: 0.25rem;
		background: transparent;
		padding: 0 1rem;
		font-weight: 600;
		font-size: 1rem;
		text-align: center;
		text-decoration: none;

		&:hover {
			background: var(--bg-dark-green-extra-light);
		}
	}

	:global .addsearch-autocomplete {
		position: absolute;
		border-bottom-right-radius: 0.5rem;
		border-bottom-left-radius: 0.5rem;
		background-color: #fff;
		width: 100%;

		ul {
			margin: 0;
			border: 1px solid var(--border-color-gray);
			border-radius: 0 0 2px 2px;
			padding: 0;
			list-style: none;
		}
		li {
			cursor: pointer;
			padding: 5px 8px;
		}
		li.active {
			background-color: var(--primary-yellow);
		}
	}
}
