.container {
	position: fixed;
	top: var(--global-menu-height);
	right: 0;
	opacity: 0.8;
	z-index: 3000;
	border-radius: var(--border-radius-lg);
	background-color: var(--primary-yellow);
	padding: var(--space-stack-md);
}

.container select {
	width: 15rem;
}

.hide {
	display: none;
}
