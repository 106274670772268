.button {
	border: 0;
	background-color: transparent;
	color: var(--text-light);
}

.icon {
	width: 1.5rem;
	height: 1.5rem;
}
