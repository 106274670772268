.hidden {
	display: none;
}

.container {
	flex: 1;
}

.inner,
.backdrop {
	position: fixed;
	top: var(--global-menu-height);
	right: 0;
	bottom: 0;
	left: 0;
	z-index: calc(var(--z-index-10) + 1);
}
.backdrop {
	background-color: var(--bg-dark-green-full-90);
}

.closeButton {
	float: right;
	margin: 0.5rem;
	border: 0;
	background: 0;
	color: white;
}

.inner {
	overflow-y: scroll;
}

.searchResult {
	margin: 0 auto;
	max-width: 920px;
}
